<div class="flex">
    <h1 qa="h1-questions">Exploratory Questions</h1>
    <app-rate-section [(rating)]="rating" (ratingChange)="onRatingChange.emit($event)"/>
</div>

<div class="note">Ask the following questions to uncover problems you can probe with What-Why-Clarify.</div>

@for (q of filtered; track q.text; let i = $index) {
    <div class="item">
        <svg data-name="Expore Question Icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19" height="18.752" viewBox="0 0 19 18.752">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_941" data-name="Rectangle 941" width="19" height="18.752" fill="none"/>
                </clipPath>
            </defs>
            <g id="Group_1170" data-name="Group 1170" clip-path="url(#clip-path)">
                <path id="Path_390" data-name="Path 390" d="M14.778,0H4.222A4.222,4.222,0,0,0,0,4.222v6.861a4.222,4.222,0,0,0,4.222,4.222H6.861v3.446l4.577-3.446h3.34A4.222,4.222,0,0,0,19,11.083V4.222A4.222,4.222,0,0,0,14.778,0m2.255,10.539a2.886,2.886,0,0,1-2.886,2.886H10.964l-2.5,1.881V13.425H5.007a2.886,2.886,0,0,1-2.886-2.886V4.766A2.886,2.886,0,0,1,5.007,1.88h9.14a2.886,2.886,0,0,1,2.886,2.886Z" fill="#b5b5b5"/>
                <path id="Path_391" data-name="Path 391" d="M9.866,8.21a1.911,1.911,0,0,1,.357.213,1.164,1.164,0,0,1,.281.318.678.678,0,0,1,.075.418,1.01,1.01,0,0,1-.144.4.987.987,0,0,1-.3.3.851.851,0,0,1-.4.136,1.382,1.382,0,0,1-.7-.121,2.367,2.367,0,0,1-.606-.395,1.4,1.4,0,0,1-.44-.666,1.919,1.919,0,0,1-.091-.873,1.866,1.866,0,0,1,.265-.711,2.833,2.833,0,0,1,.524-.608q.318-.279.652-.553c.111-.08.224-.166.341-.258s.227-.187.334-.288a1.712,1.712,0,0,0,.272-.334.856.856,0,0,0,.129-.41.688.688,0,0,0-.318-.492.766.766,0,0,0-.5-.061,1.716,1.716,0,0,0-.562.228,4.568,4.568,0,0,0-.515.357,1.431,1.431,0,0,0-.25.249c-.076.1-.155.19-.235.281a1.742,1.742,0,0,1-.265.243.766.766,0,0,1-.326.136.915.915,0,0,1-.562-.083.976.976,0,0,1-.4-.356.879.879,0,0,1-.136-.509.861.861,0,0,1,.22-.537,6.3,6.3,0,0,1,.825-.827,4.935,4.935,0,0,1,.948-.622A3.778,3.778,0,0,1,9.4,2.443a3.339,3.339,0,0,1,1.167.006,1.581,1.581,0,0,1,.675.31,2.375,2.375,0,0,1,.508.563,3.227,3.227,0,0,1,.342.689,3.548,3.548,0,0,1,.174.7,1.888,1.888,0,0,1-.076.705,2.823,2.823,0,0,1-.3.674,3.732,3.732,0,0,1-.455.591,3.148,3.148,0,0,1-.531.455l-.433.3a4.187,4.187,0,0,0-.408.327,1.391,1.391,0,0,0-.25.28c-.051.08-.031.136.06.166M8.152,11.835a1.162,1.162,0,0,1,.417-.826,1.257,1.257,0,0,1,.766-.288,1.141,1.141,0,0,1,.75.265,1.027,1.027,0,0,1,.356.82,1.083,1.083,0,0,1-.136.606,1.143,1.143,0,0,1-.387.4,1.178,1.178,0,0,1-.53.167,1.14,1.14,0,0,1-.554-.092,1.088,1.088,0,0,1-.447-.372,1.382,1.382,0,0,1-.235-.682" transform="translate(0.352 0.133)" fill="#b5b5b5"/>
            </g>
        </svg>

        <div>{{ q.text }}</div>
    </div>    
}

<app-rate-section-footer [(rating)]="rating" (ratingChange)="onRatingChange.emit($event)"/>