import {computed, Injectable, signal} from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import {
    BehaviorSubject,
    catchError,
    exhaustMap,
    finalize,
    map,
    of,
    retry,
    tap,
    throwError
} from "rxjs";
import * as Sentry from "@sentry/angular";
import {UserProfile} from "../model/userProfile";
import {Offering} from "../model/offering";

@Injectable({
    providedIn: 'root',    
})
export class AuthService {
    authenticated = signal(false);
    errorMessage = signal('');

    private loadProfile$ = new BehaviorSubject<void>(undefined);
    loadingProfile = signal(true);
    // private loadProfile$ = new Subject<void>();

    userInitials = computed(() => {
        const u = this.userProfile();
        return !! u ? u.firstName.charAt(0) + u.lastName.charAt(0) : 'I';        
    });
    
    constructor(private http: HttpClient) {
        console.log('[AuthService] constructor');
    }

    userProfile$ = this.loadProfile$.pipe(
        tap(x => console.log('[AuthService] userProfile$', x)),
        exhaustMap(() => this.http.get<ProfileResponse>('/api/user/profile')),
        map(json => {

            let user = json.user;
            Sentry.setUser({
                id: user.id,
                email: user.email,
                username: user.email + ' ' + user.lastName
            });
            
            return {
                ...user,
                offerings: [...json.offerings]
            };
        }),
        catchError(error => of(undefined)),
        tap(x => this.authenticated.set(!!x)),
        tap(x => this.userProfile.set(x)),
        tap(x => console.log('[AuthService] userProfile$', x)),
        
        finalize(() => this.loadingProfile.set(false))       
    );

    userProfile = signal<UserProfile | undefined>(undefined);
    userName = computed(() => {
        const u = this.userProfile();
        return u?.firstName + ' ' + u?.lastName;
    })
    
    offerings = computed(() => this.userProfile()?.offerings);

    login(email: string, password: string) {
        this.errorMessage.set('');

        if (!!(<any>window).qaLoginError) {
            throw Error('Sentry validation: login');
        }
        return this.http.post('/api/login?useCookies=true', {
            email,
            password
        }).pipe(
            retry(1),
            catchError(this.handleError),
        );
    }
    
    confirmEmail(userId: string, code: string) {
        this.errorMessage.set('');
        return this.http.post(`/api/auth/confirmation`, { userId, code });
    }

    validateCode(token: string, accessCode: string) {
        return this.http.post<ValidateCodeResponse>(`/api/auth/validate-code`, {
            token,
            accessCode
        }).pipe(
            map(x => x.token),
            tap(x => document.cookie = `token=${x}; path=/api; secure; samesite`)
        );
    }

    resetPassword(email: string, code: string, password: string) {
        this.errorMessage.set('');

        return this.http.post(`/api/auth/reset-password`, {
            email,
            code,
            password
        }).pipe(
            retry(1)
        );
    }

    resendConfirmationEmail(email: string) {
        this.errorMessage.set('');

        return this.http.post(`/api/auth/confirmation/resend`, {
            email
        }).pipe(
            retry(1),
            catchError(this.handleError),
        );
    }

    sendPasswordRecovery(email: string) {
        this.errorMessage.set('');

        return this.http.post(`/api/auth/password-recovery`, {
            email
        }).pipe(
            retry(1),
            catchError(this.handleError),
        );
    }
    
    public loadProfile() {
        if (!this.authenticated()) {
            this.reloadProfile();
        }
    }

    logout() {
        this.errorMessage.set('');

        if (!!(<any>window).qaLoginError) {
            throw Error('Sentry validation: logout');
        }
        
        return this.http.post('/api/logout', {}).pipe(
            retry(1),
            // shareReplay(1),
            catchError(this.handleError)
        ).subscribe(() => {
            this.authenticated.set(false);
            window.sessionStorage.clear();
            location.href = '/?' + +(new Date());                          
        });
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was:`, error.error);
        }
        // Return an observable with a user-facing error message.
        return throwError(() => 'Something bad happened; please try again later.');
    }

    reloadProfile() {
        console.log('[AuthService] reloadProfile');
        // this.loadProfile$.next();
        
        this.userProfile$.subscribe(p => {
            console.log('[AuthService] profile reloaded', p);
            console.log(this.authenticated());
        });
    }

    signInWithGoogle() {
        location.href = '/api/google/';        
    }
}

interface ProfileResponse {
    user: UserProfile;
    offerings: Offering[];
}

interface ValidateCodeRequest {
    token: string;
}
interface ValidateCodeResponse {
    token: string;
}