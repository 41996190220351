<div class="header flex">
    <div class="flex-grow-1 flex">
        <h1 qa="h1-title">{{ title }}</h1>
        <app-rate-section [rating]="rating" (ratingChange)="onRatingChange.emit($event)" qa="rate-sec"/>
    </div>
    <button (click)="expandAll()" class="sp-flat add"><i class="material-icons" qa="exp">add</i> ALL</button>
    <button (click)="collapseAll()" class="sp-flat"><i class="material-icons" qa="col">remove</i> ALL</button>
</div>

@for (list of topics; track list.role; let j = $index) {
    @if (list.role) {
        <h3 class="role" [title]="getTitle(list.role)" [attr.qa]="'role-' + (j + 1)">
            {{list.role}}
            
            <a class="sp-link brief" title="Download Job Description Brief Report" (click)="jobDescriptionEmit(list.role)">Job Description</a>
        </h3>
    }
    <div class="topics">
        @for (topic of getSelectedTopics(list); track topic.title; let i = $index) {            
            <app-discussion-topic-item (onBriefReport)="onBriefReport.emit($event)" [flat]="flat" [topic]="topic" [icon]="icon" [attr.qa]="'item-' + (j + 1) + '-' + (i + 1)"/>
        } @empty {
            <div class="no-items" qa="empty">No items to display.</div>
        }            
    </div>
} @empty {
    <div qa="empty">No items to display.</div>
}


<app-rate-section-footer [rating]="rating" (ratingChange)="onRatingChange.emit($event)" qa="rate-fot"/>